<template>
  <div class="h">
    
    <div class="title">
      <div class="title-ctn">
        <div class="title-txt">
          基于5G的农业农村
        </div>
        <div class="title-desc">
          在线培训平台系统
        </div>
      </div>
    </div>

    
    <div class="main">
      <div class="item" @click="open('http://202.120.171.81:8443/')">
        <div class="u"></div>
        <div class="u"></div>
        <div class="c c1"></div>
        <div class="d">基于5G的新媒体</div>
        <div class="d">多端共融专家知识培训系统</div>
      </div>

      <div class="item" @click="open('https://tjhsxt.hexuezx.com:1443/')">
        <div class="u">智能微课</div>
        <div class="u">验证平台</div>
        <div class="c c2"></div>
        <div class="d"></div>
        <div class="d"></div>
      </div>

      <div class="item">
        <div class="u"></div>
        <div class="u"></div>
        <div class="c c3"></div>
        <div class="d">数字化课程</div>
        <div class="d">资源平台</div>
      </div>

      <div class="item">
        <div class="u">农业智能</div>
        <div class="u">在线学习平台</div>
        <div class="c c4"></div>
        <div class="d"></div>
        <div class="d"></div>
      </div>

      <div class="item">
        <div class="u"></div>
        <div class="u"></div>
        <div class="c c5"></div>
        <div class="d">基于5G的数字化</div>
        <div class="d">农业种植物联网平台</div>
      </div>

      <div class="item" @click="open('http://202.120.171.81:8443/m/#/')">
        <div class="u">农业专家在线培训</div>
        <div class="u">资源管理系统</div>
        <div class="c c6"></div>
        <div class="d"></div>
        <div class="d"></div>
      </div>

      <div class="item" @click="open('http://202.120.171.81:8443/mpt/#/')">
        <div class="u">农业智能</div>
        <div class="u">在线管理系统</div>
        <div class="c c7"></div>
        <div class="d"></div>
        <div class="d"></div>
      </div>
    </div>
    
    <div class="bottom">

    </div>
  </div>
  
</template>

<script>
export default {
  methods: {
    open(url) {
      window.open(url)
      // console.log('1')
    }
  }

}
</script>

<style lang="scss" scoped>
.h {
  // min-width: 1920px;
  margin: 0 auto;

  .title {
    height: 360px;
    background-image: url('@/assets/img/titlebg.png');
    background-position: 50% 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;

    .title-ctn {
      text-align: center;

      .title-txt {
        // width: 530px;
        // height: 150px;
        font-family: Source Han Sans CN;
        color: #3BB248;
        font-size: 64px;
        font-weight: bold;
        letter-spacing: 0em;
      }

      .title-desc {
        font-family: Source Han Sans CN;
        font-size: 50px;
        font-weight: normal;
        letter-spacing: 0em;
        color: #000000;

      }
    }

  }

  .main {
    height: 500px;
    background-image: url('@/assets/img/mainbg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 180px;

      .u,
      .d {
        font-family: Source Han Sans CN;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0em;

        color: #FFFFFF;
        height: 35px;
      }

      .c {
        
        width: 150px;
        height: 150px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        &.c1 {
          background-image: url('@/assets/img/1.png');
        }
        &.c2 {
          background-image: url('@/assets/img/2.png');
        }
        &.c3 {
          background-image: url('@/assets/img/3.png');
        }
        &.c4 {
          background-image: url('@/assets/img/4.png');
        }
        &.c5 {
          background-image: url('@/assets/img/5.png');
        }
        &.c6 {
          background-image: url('@/assets/img/6.png');
        }
        &.c7 {
          background-image: url('@/assets/img/7.png');
        }
      }
    }
  }
  .bottom {
    height: 160px;
    background-image: url('@/assets/img/bottombg.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

}</style>